<template>
    <div>
        <section class="social-stories">
            <div class="social-header">
                <div class="social-content">
                    <span class="title-key-line">{{ $t('home.socialStories') }}</span>
                </div>
                <div class="icons">
                    <img class="img-icon cursor" @click="handelDialog(1)" :src="require('assets/images/icon/wechat.jpg')" alt="">
                    <img class="img-icon cursor" @click="handelDialog(2)" :src="require('assets/images/icon/littleRedBook.jpg')" alt="">
                    <img class="img-icon cursor" @click="handelDialog(3)" :src="require('assets/images/icon/tiktok.jpg')" alt="">
                </div>
                <el-dialog width="400px" :title="imgObj.title" :visible.sync="dialogVisibleImg">
                    <div class="dialog-body text-center">
                        <img width="260" height="260" :src="imgObj.url" alt="">
                    </div>
                    <div slot="footer" class="dialog-footer"></div>
                </el-dialog>
            </div>

            <div class="scroll-container custom-scroll-3 p-t-30 p-b-30" ref="scrollContainer3">
                <div class="item cursor social-video"
                     v-for="(socialStorie, index) of socialStories"
                     @click="handelPlay(socialStorie, index)"
                     :key="index">
                    <video :controls="!socialStorie.showPlay"
                           :poster="socialStorie.poster"
                           :src="socialStorie.src"
                           ref="video"
                           height="100%"
                           preload="none"
                           style="object-fit: cover"
                           width="100%">
                    </video>
                    <div class="play-btn" v-if="socialStorie.showPlay">▶</div>
                </div>
                <div class="left-right-btn">
                    <div class="btn-arrow cursor icon iconfont" @click="handleOffset('left', 3)"> &#xe64c;</div>
                    <div class="btn-arrow cursor icon iconfont" @click="handleOffset('right', 3)"> &#xe64d;</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'social-stories',
    data() {
        return {
            diaLogType: 1,
            dialogVisibleImg: false,
            socialStories: [
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-1.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-1.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-2.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-2.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-3.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-3.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-4.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-4.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-5.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-5.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-6.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-6.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-7.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-7.mp4',
                    showPlay: true
                },
                {
                    poster: 'https://static.vitaalumnicn.com/static/vsweb/home-5-8.png',
                    src: 'https://static.vitaalumnicn.com/static/vsweb/home-5-8.mp4',
                    showPlay: true
                }
            ]
        }
    },
    computed: {
        imgObj() {
            let type = this.diaLogType
            if (type === 1) {
                return {
                    title: this.$t('home.wechat'),
                    url: require('assets/images/icon/wechat.png')
                };
            }
            if (type === 2) {
                return {
                    title: this.$t('home.littleRedBook'),
                    url: require('assets/images/icon/littleRedBook.png')
                };
            }
            if (type === 3) {
                return {
                    title: this.$t('home.tiktok'),
                    url: require('assets/images/icon/tiktok.png')
                };
            }
            return {
                title: '',
                url: ''
            };
        }
    },
    methods: {
        handelPlay(socialStorie, index) {
            if(socialStorie.showPlay){
                socialStorie.showPlay = false
                this.$refs.video[index].play();
            }
        },
        handelDialog(type) {
            this.diaLogType = type
            this.dialogVisibleImg = true
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            }
        }
    },
}
</script>

<style lang="less" scoped>

.social-stories {
    background-color: #ebebeb;
    color: #000;
    padding: 45px 0 45px calc((100vw - 1140px)/2);
    position: relative;


    .social-header {
        display: flex;
        align-items: center;
        padding-right: 50px;
        padding-bottom: 20px;
        justify-content: space-between;

        .social-content {
            width: 50%;
            font-family: Trade Gothic LT Std Condensed, sans-serif;

            .title-key-line {
                width: 100%;
                font-weight: 700;
                font-size: 24px;
                position: relative;
                line-height: 28px;
            }

            .title-key-line:before {
                top: 0;
                width: 100%;
                margin-top: -20px;
                content: "";
                display: block;
                background-color: #e91a4c;
                position: absolute;
                height: 7px;
            }
        }

        .icons {
            .img-icon {
                height: 36px;
                width: 36px;
                margin-left: 15px;
                color: #fff;
            }

            .icon:hover {
                background: #e91a4c;
            }
        }
    }

    .scroll-container {
        width: 100%;
        height: 540px;
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        .item {
            position: relative;
            overflow: hidden;
            flex-shrink: 0;
            width: 290px;
            height: 100%;
            margin-right: 20px;
            display: flex;
            color: #fff;
            justify-content: center;
            align-items: center;
            scroll-snap-align: start;
            scroll-snap-stop: always;

            img {
                top: 0;
                object-fit: cover;
                position: absolute;
                width: auto;
                height: 100%;
                transition: transform 0.6s ease;
            }

            .play-btn {
                top: 50%;
                left: 50%;
                right: 50%;
                bottom: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                width: 40px;
                height: 40px;
                text-align: center;
                background-color: #e91a4c;
                color: #fff;
                line-height: 40px;
                font-size: 28px;
            }
        }

        .item:hover {
            .play-btn {
                background-color: #000;
            }
        }

        .left-right-btn {
            width: 100px;
            position: absolute;
            right: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            top: 50%;

            .btn-arrow {
                background: #e91a4c;
                width: 40px;
                font-size: 24px;
                font-weight: 900;
                text-align: center;
                line-height: 40px;
                color: #fff;
            }
        }
    }
}
</style>