<template>
    <div class="container-fluid p-0 m-0">
        <section class="container head">
            <div class="title">{{ $t('internationalStudents.internationalStudents') }}</div>
        </section>

        <section class="banner-img vh-80 p-0">
            <img class="img"
                 src="https://static.vitaalumnicn.com/static/vsweb/international-students-1-1.png"
                 alt="">
        </section>

        <section class="vita-content">
            <div class="content-head">
                <div class="content-title">
                    {{ $t('internationalStudents.ambitionIsInternational') }}
                </div>
            </div>

            <div class="content p-l-20 p-r-20">
                <div class="line-gray"></div>
                <div class="m-t-20" v-html="$t('internationalStudents.ambitionIsInternationalNote')"></div>
            </div>
        </section>



        <section class="vita-static-gallery row">
            <div class="left-panel col-4">
                <div class="panel-head">
                    <div class="content-title" style="width: 165px">
                        {{ $t('internationalStudents.whatToExpect') }}
                    </div>
                </div>
                <div class="content" v-html="$t('internationalStudents.whatToExpectNote')"></div>
            </div>
            <div class="right-panel col-8">
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/international-students-2-1.png">
                </picture>
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/international-students-2-2.png">
                </picture>
                <picture>
                    <img decoding="async" loading="lazy"
                         src="https://static.vitaalumnicn.com/static/vsweb/international-students-2-3.png">
                </picture>
            </div>
        </section>


        <!-- todo    没有翻译，暂时隐藏-->
        <section class="featured-posts" v-show="false">
            <div class="common-header">
                <div class="common-title font-color-black">
                    STORIES YOU MIGHT <br>
                    BE INTERESTED IN
                </div>
            </div>
            <div class="container p-0 m-0 ms-auto me-auto">
                <div class="imgs row" style="width: 1180px; margin: 0 auto">
                    <div class="col-4" v-for="story of stories">
                        <div class="panel">
                            <div class="item">
                                <img class="img" :src="story.img" alt="">
                            </div>
                            <div class="footer-panel">
                                <div class="footer-title">
                                    {{ story.type }}
                                </div>
                                <div class="footer-sub">
                                    {{ story.title }}
                                </div>
                                <div class="time">
                                    {{ story.time }}
                                </div>
                                <div class="icon-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="vita-content">
            <div class="content-head">
                <div class="content-title">
                    {{ $t('internationalStudents.paymentBooking') }}
                </div>
            </div>

            <div class="content p-l-20 p-r-20">
                <div class="line-gray"></div>
                <div class="m-t-20" v-html="$t('internationalStudents.paymentBookingNote')"></div>
            </div>
        </section>

        <section class="banner-img vh-60">
            <img class="img"
                 src="https://static.vitaalumnicn.com/static/vsweb/international-students-4-1.png"
                 alt="">
        </section>

        <social-stories></social-stories>

        <section class="faqs">
            <div class="faqs-head">
                <div class="faqs-title">{{ $t('internationalStudents.faqs') }}</div>
            </div>
            <div class="accordion-group">
                <div class="accordion-card" :class="index === 0 ? 'border-top' : ''" v-for="(item, index) of faqs">
                    <div class="accordion-header cursor" @click="handleSpan(index)">
                        <div class="accordion-rn">{{ (index + 1)|rnFilter }}</div>
                        <div class="accordion-question">
                            <span class="faq-title">{{ item.title }}</span>
                        </div>
                        <div class="accordion-more">
                            <span v-if="faqSpans[index]">LESS -</span>
                            <span v-else>MORE +</span>
                        </div>
                    </div>
                    <div class="accordion-content" v-if="faqSpans[index]" v-html="item.content"></div>
                    <div class="underline"></div>
                </div>
            </div>
        </section>

        <section class="banner-img vh-80">
            <img class="img"
                 src="https://static.vitaalumnicn.com/static/vsweb/international-students-6-1.png"
                 alt="">
        </section>

    </div>
</template>

<script>
import socialStories from '@/components/social-stories'
export default {
    components: {
        socialStories
    },
    name: "international-students",
    filters: {
        rnFilter(rn) {
            return ('00' + rn).slice(-2);
        }
    },
    data() {
        return {
            diaLogType: 1,
            dialogVisibleImg: false,
            // todo 没有翻译
            stories: [
                {
                    type: "News",
                    title: "Studying in the UK: the Ultimate Guide for International Students 2023",
                    link: "",
                    time: "12.05.23 - 11 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/international-students-3-1.png"
                },
                {
                    type: "Productivity",
                    title: "How to choose the right university degree",
                    link: "",
                    time: " 04.07.23 - 10 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/international-students-3-2.png"
                },
                {
                    type: "Wellbeing",
                    title: "Managing Stress When Studying Abroad",
                    link: "",
                    time: "12.04.23 - 09 Mins Read",
                    img: "https://static.vitaalumnicn.com/static/vsweb/international-students-3-3.png"
                }
            ],
            videos: [],
            faqSpans: [false, false, false, false, false, false, false, false, false, false, false, false, false, false]
        }
    },
    computed: {
        faqs() {
            return [
                {
                    rn: 1,
                    title: this.$t('internationalStudents.faqs1'),
                    content: this.$t('internationalStudents.faqsNote1')
                },
                {
                    rn: 2,
                    title: this.$t('internationalStudents.faqs2'),
                    content: this.$t('internationalStudents.faqsNote2')
                },
                {
                    rn: 3,
                    title: this.$t('internationalStudents.faqs3'),
                    content: this.$t('internationalStudents.faqsNote3')
                },
                {
                    rn: 4,
                    title: this.$t('internationalStudents.faqs4'),
                    content: this.$t('internationalStudents.faqsNote4')
                },
                {
                    rn: 5,
                    title: this.$t('internationalStudents.faqs5'),
                    content: this.$t('internationalStudents.faqsNote5')
                },
                {
                    rn: 6,
                    title: this.$t('internationalStudents.faqs6'),
                    content: this.$t('internationalStudents.faqsNote6')
                },
                {
                    rn: 7,
                    title: this.$t('internationalStudents.faqs7'),
                    content: this.$t('internationalStudents.faqsNote7')
                },
                {
                    rn: 8,
                    title: this.$t('internationalStudents.faqs8'),
                    content: this.$t('internationalStudents.faqsNote8')
                },
                {
                    rn: 9,
                    title: this.$t('internationalStudents.faqs9'),
                    content: this.$t('internationalStudents.faqsNote9')
                },
                {
                    rn: 10,
                    title: this.$t('internationalStudents.faqs10'),
                    content: this.$t('internationalStudents.faqsNote10')
                },
                {
                    rn: 11,
                    title: this.$t('internationalStudents.faqs11'),
                    content: this.$t('internationalStudents.faqsNote11')
                },
                {
                    rn: 12,
                    title: this.$t('internationalStudents.faqs12'),
                    content: this.$t('internationalStudents.faqsNote12')
                },
                {
                    rn: 13,
                    title: this.$t('internationalStudents.faqs13'),
                    content: this.$t('internationalStudents.faqsNote13')
                },
                {
                    rn: 14,
                    title: this.$t('internationalStudents.faqs14'),
                    content: this.$t('internationalStudents.faqsNote14')
                },
                {
                    rn: 15,
                    title: this.$t('internationalStudents.faqs15'),
                    content: this.$t('internationalStudents.faqsNote15')
                }
            ]
        },
        imgObj() {
            let type = this.diaLogType
            if (type === 1) {
                return {
                    title: this.$t('home.wechat'),
                    url: require('assets/images/icon/wechat.png')
                };
            }
            if (type === 2) {
                return {
                    title: this.$t('home.littleRedBook'),
                    url: require('assets/images/icon/littleRedBook.png')
                };
            }
            if (type === 3) {
                return {
                    title: this.$t('home.tiktok'),
                    url: require('assets/images/icon/tiktok.png')
                };
            }
            return {
                title: '',
                url: ''
            };
        }
    },
    methods: {
        handelDialog(type) {
            this.diaLogType = type
            this.dialogVisibleImg = true
        },
        handleSpan(index) {
            this.faqSpans[index] = !this.faqSpans[index];
            this.$forceUpdate();
        },
        handleOffset(offset, type = null) {
            let scrollContainer = this.$refs['scrollContainer' + type];
            if (offset === 'left') {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft - 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            } else {
                scrollContainer.scrollTo({
                    left: scrollContainer.scrollLeft + 300,
                    behavior: 'smooth' // 添加平滑滚动效果
                });
            }
        }
    }
}
</script>

<style lang="less" scoped>

.head {
    padding: 135px 45px 92px;
    background: #fff;
    margin: 0 auto;
    color: #000;

    .title {
        font-size: 80px;
        font-weight: 700;
        font-family: Trade Gothic LT Std Condensed, sans-serif;
    }

    .title:before {
        margin-top: -15px;
        width: 86px;
        content: "";
        display: block;
        background-color: #e91a4c;
        position: absolute;
        height: 7px;
    }
}

.banner-img {
    height: 80vh;
    background-color: #fff;
    padding: 0 45px;

    .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

}

.banner-img-header {
    height: 80vh;
    background-color: #fff;
    padding: 0 45px;

    .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.vita-static-gallery {
    background-color: #fff;
    color: #000;
    padding: 0 0 0 45px;
    //height: 100vh;

    .left-panel {
        padding-top: 60px;


        .panel-head {
            display: flex;
            color: #000;

            .content-title {
                font-size: 26px;
                font-weight: 700;
                line-height: 1.2;
                font-family: Trade Gothic LT Std Condensed, sans-serif;
            }

            .content-title:before {
                margin-top: -10px;
                width: 100%;
                content: "";
                display: block;
                background-color: #e91a4c;
                height: 7px;
                margin: 10px 0;
            }
        }

        .content {
            width: 306px;
            padding: 30px 0 80px;
            font-size: 16px;
        }

        .label-items {
            list-style-type: none;
            padding: 30px 0;
            font-size: 12px;

            .label-item {
                position: relative;
                padding: 5px 20px 0 20px;
            }

            .label-item::before {
                content: "\2713"; /* Unicode编码表示对号 */
                position: absolute;
                left: 0;
                color: #e91a4c; /* 设置红色 */
            }
        }
    }

    .right-panel {
        padding: 0;
        background-color: #000;
        color: #fff;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0;

        picture {
            padding-top: 0;
            margin-bottom: 0;
            display: block;
            position: relative;
            height: 100%;

            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        picture:nth-of-type(1) {
            height: 100%;
            grid-column: 1;
            grid-row: 1/3;
            padding-top: 153%;
        }

        picture:nth-of-type(2) {
            grid-column: 2;
            grid-row: 1;
            padding-top: 0;
            margin-bottom: 0;
        }

        picture:nth-of-type(3) {
            grid-column: 2;
            grid-row: 2;
            padding-top: 0;
            margin-bottom: 0;
        }
    }
}


.featured-posts {
    background-color: #f5f5f5;
    color: #000;
    padding: 45px;

    .imgs {
        margin-top: 20px;

        .panel {
            cursor: pointer;

            .item {
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                font-weight: 900;
                color: #fff;
                aspect-ratio: 1 / 1;
                transition: transform 0.6s ease;

                img {
                    top: 0;
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: 100%;
                    transition: transform 0.5s ease;
                }
            }

            .footer-panel {
                background-color: #fff;
                font-weight: 700;
                position: relative;
                padding: 10px;
                height: 210px;
                color: #000;

                .footer-title {
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    text-transform: uppercase;
                    color: #a7a7a7;
                    font-size: 16px;
                    line-height: 1.8;
                }

                .footer-sub {
                    font-size: 26px;
                    font-weight: 900;
                    text-transform: uppercase;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                }

                .footer-sub::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 50px;
                    background-color: #5b5b5b;
                    height: 1px;
                    top: 5px;
                }

                .icon-btn {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    background-color: #e91a4c;
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                    z-index: 1;
                    right: 0;
                    bottom: 0;
                }

                .time {
                    position: absolute;
                    font-size: 14px;
                    z-index: 1;
                    left: 10px;
                    bottom: 10px;
                    font-family: Trade Gothic LT Std Condensed, sans-serif;
                    color: #a7a7a7;
                }
            }
        }

        .panel:hover {
            .item img {
                transform: scale(1.15);
            }

            .footer-sub {
                color: #e91a4c;
            }

            .footer-sub::after {
                background-color: #e91a4c;
            }
        }

    }

}


.social-stories {
    margin-left: auto;
    background-color: #fff;
    color: #000;
    padding: 45px 0 45px calc((100vw - 1140px)/2);
    position: relative;


    .social-header {
        display: flex;
        align-items: center;
        padding-right: 60px;
        padding-bottom: 40px;
        justify-content: space-between;

        .social-content {
            width: 50%;
            font-family: Trade Gothic LT Std Condensed, sans-serif;

            .title-key-line {
                width: 100%;
                font-weight: 700;
                font-size: 24px;
                position: relative;
                line-height: 28px;
            }

            .title-key-line:before {
                top: 0;
                width: 100%;
                margin-top: -20px;
                content: "";
                display: block;
                background-color: #e91a4c;
                position: absolute;
                height: 7px;
            }
        }

        .icons {
            .img-icon {
                height: 36px;
                width: 36px;
                margin-left: 15px;
                background: #000;
                color: #fff;
            }

            .icon:hover {
                background: #e91a4c;
            }
        }
    }

    .scroll-container {
        padding-bottom: 20px;
        width: 100%;
        height: 540px;
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        .item {
            position: relative;
            overflow: hidden;
            flex-shrink: 0;
            width: 290px;
            height: 100%;
            margin-right: 20px;
            display: flex;
            color: #fff;
            justify-content: center;
            align-items: center;
            scroll-snap-align: start;
            scroll-snap-stop: always;

            img {
                top: 0;
                object-fit: cover;
                position: absolute;
                width: auto;
                height: 100%;
                transition: transform 0.6s ease;
            }
        }

        .left-right-btn {
            width: 100px;
            position: absolute;
            right: 30px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            top: 50%;

            .btn-arrow {
                background: #e91a4c;
                width: 40px;
                line-height: 40px;
                font-size: 40px;
                font-weight: 900;
                text-align: center;
                color: #fff;
            }
        }
    }
}


</style>
